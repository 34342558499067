import * as React from 'react';
import clsx from 'clsx';
import * as scss from './styledHeading.module.scss';

export type Props = {
  children?: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  hasSwoosh?: boolean;
};

export const StyledHeading = ({
  children,
  tag: Component = 'h2',
  hasSwoosh = true,
}: Props) => {
  return (
    <div className={clsx(scss.wrapper)}>
      <div className={clsx(scss.inner, hasSwoosh && scss.hasSwoosh)}>
        <Component>{children}</Component>
      </div>
    </div>
  );
};
