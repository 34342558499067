import * as React from 'react';
import clsx from 'clsx';
import { GameContext } from './GameContext';
import { Button } from 'src/components/ui/button/button';
import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import * as scss from './Game.module.scss';
import * as types from '../../../types';
import { stat } from 'fs';
import { date } from '@storybook/addon-knobs';

// types
export type clueType = {
  clue: string;
  image: types.ImageFields;
};

export type gameDataType = {
  id: string;
  poster: { url: string };
  question: string;
  choices: { text: string }[];
  answer: string;
  clues: clueType[];
};

export type gameData = {
  id: string;
  heading: string;
  clueButtonLabel: string;
  continueButtonLabel: string;
  playAgainLabel: string;
  questions: gameDataType[];
};

export type Props = {
  data: gameData;
};

const Game: React.FC<Props> = ({ data }) => {
  const gameRef = React.useRef<HTMLDivElement | null>(null);

  const { state, dispatch } = React.useContext(GameContext);

  // effects
  React.useEffect(() => {
    dispatch({ type: 'LOAD_DATA', payload: data.questions });
  }, [data, dispatch]);

  React.useEffect(() => {
    if (state.gameOver && gameRef.current) {
      gameRef.current.scrollIntoView();
    }
  }, [state.gameOver]);

  const getGameHeader = () => {
    return (
      <div className={clsx(scss.gameHeader)}>
        <StyledHeading hasSwoosh={false}>{data.heading}</StyledHeading>
      </div>
    );
  };

  const getClueImage = () => {
    return (
      <div className={clsx(scss.gameFeaturedImageWrapper)}>
        <div className={clsx(scss.gameFeaturedImage)}>
          <div className={clsx(scss.gameFeaturedImageBg)}>
            <div className={clsx(scss.gameFeaturedImageBgInner)} />
          </div>
          <div className={clsx(scss.gameFeaturedImageInner)}>
            {state.currentChoice ? (
              <img
                src={state.gameData[state.currentItem].poster.url}
                alt="Poster"
              />
            ) : (
              <img
                src={
                  state.gameData[state.currentItem].clues[state.currentClue]
                    .image.src.lg.url
                }
                alt="Poster"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const getClueProgress = () => {
    return (
      <div className={clsx(scss.gameClueProgress)}>
        Clue {state.currentClue + 1} of{' '}
        {state.gameData[state.currentItem].clues.length}
      </div>
    );
  };

  const getClueDescription = () => {
    return (
      <div
        className={clsx(scss.gameClueDescription)}
        dangerouslySetInnerHTML={{
          __html:
            state.gameData[state.currentItem].clues[state.currentClue].clue,
        }}
      />
    );
  };

  const getClueCTA = () => {
    if (!state.gameOver && !state.currentChoice) {
      return (
        <div className={clsx(scss.gameClueCta)}>
          {state.currentClue !==
            state.gameData[state.currentItem].clues.length - 1 && (
            <Button
              outline
              mode={`light`}
              label={data.clueButtonLabel}
              tag={`button`}
              onClick={() => dispatch({ type: 'NEXT_CLUE' })}
            />
          )}
        </div>
      );
    }
  };

  const getGameProgress = () => {
    return (
      <div className={clsx(scss.gameProgress)}>
        Title {state.currentItem + 1} of {state.gameData.length}
      </div>
    );
  };

  const getGameQuestion = () => {
    return (
      <div className={clsx(scss.gameQuestion)}>
        <div
          dangerouslySetInnerHTML={{
            __html: state.gameData[state.currentItem].question,
          }}
        />
      </div>
    );
  };

  const getChoiceClasses = (choiceIndex: number) => {
    const curChoice = state.currentChoice;
    const curItem = state.gameData[state.currentItem];
    const choices = curItem.choices;
    const curChoiceText = choices[choiceIndex].text;
    const curChoiceAnswer = curItem.answer;

    return clsx(
      curChoice &&
        curChoice === curChoiceText &&
        curChoice !== curChoiceAnswer &&
        'is-error',

      curChoice && curChoiceAnswer === curChoiceText && 'is-success'
    );
  };

  const getGameChoices = () => {
    return (
      <div className={clsx(scss.gameChoices)}>
        <ul>
          {state.gameData[state.currentItem].choices.map(
            (choice, choiceIndex) => (
              <li
                key={`${state.gameData[state.currentItem].id}_${choiceIndex}`}
                className={getChoiceClasses(choiceIndex)}>
                <button
                  className={clsx('is-error')}
                  onClick={() =>
                    dispatch({
                      type: 'SUBMIT_ANSWER',
                      payload: choice.text,
                    })
                  }>
                  {choice.text}
                </button>
              </li>
            )
          )}
        </ul>
      </div>
    );
  };

  const getContinueButton = () => {
    if (state.currentChoice) {
      return (
        <div className={clsx(scss.gameContinue)}>
          <Button
            label={data.continueButtonLabel}
            tag={`button`}
            onClick={() => dispatch({ type: 'NEXT_ITEM' })}
          />
        </div>
      );
    }
  };

  const getPlayAgain = () => {
    if (state.gameOver) {
      return (
        <div className={clsx(scss.gameGameOver)}>
          <div className={clsx(scss.gameGameOverMessage)}>
            Thank you for playing!
            <br />
            <br />
            You have identified{' '}
            <span>
              {state.correctAnswers} out of {state.gameData.length}
            </span>{' '}
            movies, and used{' '}
            <span>
              {state.usedClues} {state.usedClues === 1 ? 'clue' : 'clues'}.
            </span>{' '}
            <br />
            Your score is{' '}
            <span>
              {state.score} /{' '}
              {state.gameData.reduce(
                (counter, { clues }) => (counter += clues.length),
                0
              )}
            </span>
          </div>
          <Button
            label={data.playAgainLabel}
            tag={`button`}
            onClick={() => dispatch({ type: 'RESET_GAME' })}
          />
        </div>
      );
    }
  };

  return state.gameData.length > 0 ? (
    <section ref={gameRef} className={clsx(scss.game, scss.wrapper)}>
      <div className={clsx(scss.gameContainer)}>
        {getGameHeader()}
        {!state.gameOver && (
          <div className={clsx(scss.gameGrid)}>
            {/* COLUMN 1 */}
            <div className={clsx(scss.gameGridItem)}>
              {getClueImage()}
              <div className={clsx(scss.gameClue)}>
                {getClueProgress()}
                {getClueDescription()}
                {getClueCTA()}
              </div>
            </div>
            {/* COLUMN 2 */}
            <div className={clsx(scss.gameGridItem)}>
              <div className={clsx(scss.gameGridItemInner)}>
                {getGameProgress()}
                {getGameQuestion()}
                {getGameChoices()}
                {getContinueButton()}
              </div>
            </div>
          </div>
        )}
        {getPlayAgain()}
      </div>
    </section>
  ) : (
    ''
  );
};

export default Game;
