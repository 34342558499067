import * as React from 'react';
import clsx from 'clsx';
import { GameContext } from './GameContext';
import { Button } from 'src/components/ui/button/button';
import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import * as scss from './Game.module.scss';
import { ImageFields } from '../../../types';

// types
export type gameTypeScore = {
  typeId: string;
  score: number;
};

export type gameAnswer = {
  answerId: string;
  text: string;
  typeScores: gameTypeScore[];
};

export type gameQuestion = {
  questionId: string;
  title: string;
  answers: gameAnswer[];
  image: ImageFields;
  imageCaption: string;
};

export type gamePersonalityType = {
  typeId: string;
  title: string;
  description1: string;
  description2: string;
  image: ImageFields;
};

export type gameDataType = {
  id: string;
  heading: string;
  subheading: string;
  personalityTypes: gamePersonalityType[];
  questions: gameQuestion[];
  questionProgressText: string;
  confirmButtonText: string;
  congratulationsText: string;
  tryAgainButtonText: string;
};

export type Props = {
  data: gameDataType;
};

const Game = ({ data }: Props) => {
  const gameRef = React.useRef<HTMLDivElement | null>(null);

  const [selectedAnswer, setSelectedAnswer] = React.useState('');

  const { state, dispatch } = React.useContext(GameContext);

  // effects
  React.useEffect(() => {
    dispatch({
      type: 'LOAD_DATA',
      payload: {
        questions: data.questions,
        types: data.personalityTypes,
      },
    });
  }, [data, dispatch]);

  React.useEffect(() => setSelectedAnswer(state.currentAnswer?.text || ''), [
    state.currentAnswer,
  ]);

  React.useEffect(() => {
    if (state.gameOver && gameRef.current) {
      gameRef.current.scrollIntoView();
    }
  }, [state.gameOver]);

  const getAriaLive = () => (
    <div className="visually-hidden" aria-live={`polite`}>
      {!state.gameOver &&
        !selectedAnswer &&
        `Question ${state.currentQuestionNumber + 1} of ${
          data.questions?.length
        }. ${data.questions[state.currentQuestionNumber].title}.`}

      {!state.gameOver &&
        state.currentAnswer &&
        `You selected ${selectedAnswer}. Select the "${
          data.confirmButtonText
        }" button below to lock in your choice and ${
          state.currentQuestionNumber + 1 >= data.questions?.length
            ? 'finish the game'
            : 'go to the next question'
        }, or select another answer.`}

      {state.gameOver &&
        `Congratulations!\n ${state.highestTypeScore?.description1}. ${state.highestTypeScore?.description2}. Select the ${data.tryAgainButtonText} button to start the quiz again.`}
    </div>
  );

  const getGameHeader = () => (
    <div className={clsx(scss.gameHeader)}>
      <StyledHeading hasSwoosh={false}>{data.heading}</StyledHeading>
      <p className={clsx(scss.gameSubheader)}>
        {state.gameOver ? data.congratulationsText : data.subheading}
      </p>
    </div>
  );

  const getQuestionImage = () => (
    <div className={clsx(scss.col, scss.colLeft)}>
      <span className={scss.featImgSpacer}>{'\u200B'}</span>
      <img
        className={scss.featImg}
        src={data.questions[state.currentQuestionNumber].image.src.lg?.url}
        alt={data.questions[state.currentQuestionNumber].image.alt}
      />
    </div>
  );

  const getGameProgress = () => {
    const progress = data.questionProgressText
      .replace('{currentQuestion}', '' + (state.currentQuestionNumber + 1))
      .replace('{totalQuestions}', '' + data.questions?.length);

    return <div className={clsx(scss.gameProgress)}>{progress}</div>;
  };

  const getGameQuestion = () => (
    <div className={clsx(scss.gameQuestion)}>
      <div
        dangerouslySetInnerHTML={{
          __html: data.questions[state.currentQuestionNumber].title,
        }}
      />
    </div>
  );

  const getAnswerClasses = (answerId: string) =>
    clsx(state.currentAnswer?.answerId === answerId && 'is-selected');

  const getAnswerAria = (answer: gameAnswer, answerIndex: number) =>
    state.currentAnswer
      ? state.currentAnswer?.answerId === answer.answerId
        ? `${answer.text} - Selected`
        : `${answer.text} - Not selected`
      : `Answer ${answerIndex + 1}: ${answer.text}`;

  const getGameAnswers = () => (
    <div className={clsx(scss.gameChoices)}>
      <ul
        aria-label={`${
          data.questions[state.currentQuestionNumber].answers.length
        } Answer Buttons`}>
        {data.questions[state.currentQuestionNumber].answers.map(
          (answer: gameAnswer, answerIndex: number) => (
            <li
              key={`${
                data.questions[state.currentQuestionNumber].questionId
              }_${answerIndex}`}
              className={getAnswerClasses(answer.answerId)}>
              <button
                dangerouslySetInnerHTML={{
                  __html: answer.text,
                }}
                aria-label={getAnswerAria(answer, answerIndex)}
                onClick={() =>
                  dispatch({
                    type: 'SET_ANSWER',
                    payload: answer,
                  })
                }></button>
            </li>
          )
        )}
      </ul>
    </div>
  );

  const getContinueButton = () => (
    <div className={clsx(scss.gameContinue)}>
      <Button
        aria-label={`Quiz ${data.confirmButtonText} Action button`}
        label={data.confirmButtonText}
        disabled={!state.currentAnswer}
        aria-disabled={!state.currentAnswer}
        tag={`button`}
        onClick={() => dispatch({ type: 'NEXT_QUESTION' })}
      />
    </div>
  );

  const getPlayAgain = () =>
    state.gameOver && (
      <div className={clsx(scss.gameGameOver)}>
        <div className={clsx(scss.gameGameOverMessage)}>
          {state.highestTypeScore?.description1}
          <br />
          <img
            className={clsx(scss.gamePersonalityTypeImage)}
            src={state.highestTypeScore?.image.src.lg?.url}
            alt={`${state.highestTypeScore?.image.alt}`}
          />
          <br />
          {state.highestTypeScore?.description2}
        </div>
        <Button
          label={data.tryAgainButtonText}
          tag={`button`}
          onClick={() => dispatch({ type: 'RESET_GAME' })}
        />
      </div>
    );

  return (
    data.questions.length > 0 && (
      <section
        ref={gameRef}
        id="activity-section"
        className={clsx(scss.game, scss.wrapper)}>
        {getAriaLive()}
        <div className={clsx(scss.gameContainer)}>
          {getGameHeader()}
          {!state.gameOver && (
            <div className={clsx(scss.gameGrid)}>
              <div className={clsx(scss.gameGridItem)}>
                {getQuestionImage()}
              </div>
              <div className={clsx(scss.gameGridItem)}>
                <div className={clsx(scss.gameGridItemInner)}>
                  {getGameProgress()}
                  {getGameQuestion()}
                  {getGameAnswers()}
                  {getContinueButton()}
                </div>
              </div>
            </div>
          )}
          {getPlayAgain()}
        </div>
      </section>
    )
  );
};

export default Game;
