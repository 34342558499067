import * as React from 'react';
import clsx from 'clsx';

import { useGame } from './GameContext';
import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import { Button } from 'src/components/ui/button/button';

import * as scss from './Game.module.scss';

import { ImageFields } from '../../../types';
import { getStrapiImageSource } from './../../../utils/strapiDataHelpers';

export type GameItem = {
  question: string;
  answers: { answer: string }[];
  correctAnswer: string;
  image: ImageFields;
};

export type Data = {
  id: number;
  heading: string;
  subheading: string;
  questionLabel: string;
  continueButtonLabel: string;
  submitButtonLabel: string;
  thankYouLabel: string;
  yourScoreLabel: string;
  finishGameLabel: string;
  playAgainLabel: string;
  questions: GameItem[];
};

export type Props = {
  data: Data;
  id?: string;
};

const Game = ({ data, id = 'activity-section' }: Props) => {
  // misc
  const isBrowser = typeof window !== 'undefined';

  // refs
  const gameRef = React.useRef<HTMLDivElement>(null);

  // context
  const { state, dispatch } = useGame();

  // states
  const [lockedInChoice, setLockedInChoice] = React.useState('');

  // Effect - Load game data
  React.useEffect(() => {
    if (state.gameData.length) return;

    if (!data.questions?.length) return;

    // Get random set of questions.
    const payload = data.questions
      .sort(() => 0.5 - Math.random())
      .slice(0, state.maxGameLength);

    dispatch({ type: 'SET_GAME_DATA', payload });
  }, [state, data, dispatch]);

  // Effect - Lock in choice
  React.useEffect(() => {
    setLockedInChoice('');
  }, [state.currentItem]);

  // Effect - Analytics - Game Start
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      window?.dataLayer?.push({
        event: 'Scene It Game',
        action: 'gameStart',
      });
    }
  }, [isBrowser]);

  // Effect - Analytics - Game Over
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.gameOver) {
        window?.dataLayer?.push({
          event: 'Scene It Game',
          action: 'gameOver',
          data: {
            score: state.score,
          },
        });
      }
    }
  }, [isBrowser, state.gameOver, state.score]);

  // Effect - Analytics - Question
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.currentItem !== undefined) {
        window?.dataLayer?.push({
          event: 'Scene It Game',
          action: 'quizQuestion',
          data: {
            progress: `${state.progress}`,
            question: state.gameData[state?.currentItem]?.question,
          },
        });
      }
    }
  }, [isBrowser, state.gameData, state.currentItem, state.progress]);

  // Effect - Analytics - Choice
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.currentItem !== -1) {
        if (state.currentAnswer) {
          window?.dataLayer?.push({
            event: 'Scene It Game',
            action: 'quizChoice',
            data: {
              answer: state.currentAnswer,
              correctAnswer: state.gameData[state.currentItem].correctAnswer,
            },
          });
        }
      }
    }
  }, [isBrowser, state.gameData, state.currentAnswer, state.currentItem]);

  if (state.gameData.length === 0) return null;

  return (
    <div ref={gameRef} id={id} className={scss.wrapper}>
      {/* Aria Live */}
      <div className="visually-hidden" aria-live="polite">
        {!state.gameOver &&
          !lockedInChoice &&
          `Please select one of the two answer buttons to choose your answer.`}

        {!state.gameOver &&
          !state.currentAnswer &&
          !!lockedInChoice &&
          `You selected ${lockedInChoice}. Select the "${data.submitButtonLabel}" button below to lock in your choice, or select another answer.`}

        {!state.gameOver &&
          state.currentAnswer &&
          state.currentAnswer !==
            state.gameData[state.currentItem].correctAnswer.toLowerCase() && (
            <>
              Sorry, that's incorrect. The correct answer is
              {state.gameData[state.currentItem].correctAnswer}.
              {state.progress !== state.maxGameLength
                ? `Please select the ${data.continueButtonLabel} button for the next question.`
                : `Please select the "${data.finishGameLabel}" button to finish.`}
            </>
          )}

        {!state.gameOver &&
          state.currentAnswer &&
          state.currentAnswer ===
            state.gameData[state.currentItem].correctAnswer.toLowerCase() && (
            <>
              That's correct!
              {state.progress !== state.maxGameLength
                ? `Please select the "${data.continueButtonLabel}" button for the next question.`
                : `Please select the "${data.finishGameLabel}" button to finish.`}
            </>
          )}

        {state.gameOver &&
          `Thank you for playing! Your score is ${state.score}. Select the "${data.playAgainLabel}" button to play again.`}
      </div>

      <div className={scss.container}>
        {/* heading */}
        <div className={scss.heading}>
          <StyledHeading hasSwoosh={false}>{data.heading}</StyledHeading>
          {!state.gameOver && (
            <p className={clsx(scss.subheader)}>{data.subheading}</p>
          )}
        </div>

        {/* Screen - Game Over */}
        <ScreenGameOver show={state.gameOver} data={data} />

        {/* Screen - Game Single */}
        {!state.gameOver && (
          <ScreenGameItem
            data={data}
            gameRef={gameRef}
            isBrowser={isBrowser}
            lockedInChoice={lockedInChoice}
            show={!state.gameOver || !state.currentItem}
            setLockedInChoice={setLockedInChoice}
          />
        )}
      </div>
    </div>
  );
};

export default Game;

type ScreenProps = {
  data: Data;
  show?: boolean;
};

export const ScreenGameOver = ({ show, data }: ScreenProps) => {
  const { state, dispatch } = useGame();

  const reset = () => {
    dispatch({ type: 'RESET_GAME' });
  };

  /**
   * Get the game score text, replacing score token
   */
  function getScore() {
    return data.yourScoreLabel.replace('{{SCORE}}', '' + state.score);
  }

  if (!show) return null;

  return (
    <div className={scss.screenGameOver}>
      <div className={scss.content}>
        <p dangerouslySetInnerHTML={{ __html: data.thankYouLabel }}></p>
        {data.yourScoreLabel && (
          <p dangerouslySetInnerHTML={{ __html: getScore() }}></p>
        )}
      </div>

      <div className={clsx(scss.cta)}>
        <Button
          className={clsx(scss.btnAction)}
          tag="button"
          label={data.playAgainLabel}
          onClick={reset}
        />
      </div>
    </div>
  );
};

export type ScreenGameItemProps = ScreenProps & {
  gameRef: React.RefObject<HTMLDivElement>;
  isBrowser?: boolean;
  lockedInChoice: string;
  setLockedInChoice: React.Dispatch<React.SetStateAction<string>>;
};

export const ScreenGameItem = ({
  data,
  gameRef,
  isBrowser,
  show,
  ...props
}: ScreenGameItemProps) => {
  const { state, dispatch } = useGame();

  const item = state.gameData[state.currentItem];
  const itemCorrectAnswer = item.correctAnswer.toLowerCase();
  const currentAnswer = state.currentAnswer?.toLowerCase() ?? null;
  const itemAnswers = item.answers.map(({ answer }) => answer.toLowerCase());

  /**
   * Get the question status text, replacing current and total numbers
   */
  function getQuestionStatus() {
    return data.questionLabel
      .replace('{{CURRENT}}', '' + state.progress)
      .replace('{{TOTAL}}', '' + state.maxGameLength);
  }

  /**
   * Submit locked in answer
   */
  function submitLockedInAnswer() {
    dispatch({
      type: 'SET_ANSWER',
      payload: props.lockedInChoice,
    });
  }

  /**
   * Proceed to next Item
   */
  function proceed() {
    if (gameRef.current) {
      gameRef.current.scrollIntoView();
    }

    props.setLockedInChoice('');

    dispatch({ type: 'SET_CURRENT_ITEM', payload: state.currentItem + 1 });

    // GA_TAG_EVENT
    if (isBrowser) {
      window?.dataLayer?.push({
        event: 'Scene It Game',
        action: 'quizContinue',
      });
    }
  }

  if (!show) return null;

  return (
    <div className={scss.screenGame}>
      <div className={scss.screenGameGrid}>
        {/* column 1 */}
        <div className={clsx(scss.col, scss.colLeft)}>
          <span className={scss.featImgSpacer}>{'\u200B'}</span>
          <img
            className={scss.featImg}
            src={getStrapiImageSource(item.image.src.lg)}
            alt={item.image.alt}
          />
        </div>

        {/* column 2 */}
        <div className={clsx(scss.col, scss.colRight)}>
          {/* progress */}
          <div
            className={scss.progress}
            dangerouslySetInnerHTML={{ __html: getQuestionStatus() }}></div>

          {/* question */}
          <div
            className={scss.question}
            dangerouslySetInnerHTML={{ __html: item.question }}></div>

          {/* choices */}

          <ul className={scss.choiceList} arial-label="Answer buttons">
            {itemAnswers.map((choice, index) => (
              <li
                key={choice}
                className={clsx({
                  'is-selected': props.lockedInChoice.toLowerCase() === choice,
                  'is-error':
                    currentAnswer && currentAnswer !== itemCorrectAnswer
                      ? choice === props.lockedInChoice.toLowerCase()
                      : false,
                  'is-success': currentAnswer && itemCorrectAnswer === choice,
                })}>
                <button
                  aria-label={
                    currentAnswer && item !== undefined
                      ? itemCorrectAnswer === choice
                        ? `Correct Answer: ${choice}`
                        : `Incorrect Answer: ${choice}`
                      : `Answer ${index + 1}: ${choice}`
                  }
                  dangerouslySetInnerHTML={{ __html: choice.toUpperCase() }}
                  onClick={() => {
                    if (!currentAnswer) {
                      props.setLockedInChoice(choice);
                    }
                  }}></button>
              </li>
            ))}
          </ul>

          {/* controls */}
          <ul className={scss.controlList} aria-label="Quiz Action Buttons">
            <li>
              <Button
                className={clsx(scss.btnAction)}
                tag="button"
                label={data.submitButtonLabel}
                disabled={!props.lockedInChoice || state.currentAnswer !== null}
                onClick={submitLockedInAnswer}
              />
            </li>
            <li>
              <Button
                className={clsx(scss.btnAction)}
                tag="button"
                label={
                  state.progress !== state.maxGameLength
                    ? data.continueButtonLabel
                    : data.finishGameLabel
                }
                disabled={!state.currentAnswer}
                onClick={proceed}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
