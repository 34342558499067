import React from 'react';
import clsx from 'clsx';

import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import { gameDataType } from '../Game';
import { stateType } from '../GameContext';

import * as scss from '../Game.module.scss';

type Props = {
  data: gameDataType;
  state: stateType;
  numberOfQuestions: number;
};

const Header = ({ data, state, numberOfQuestions }: Props) => {
  const congratulationsText = data.congratulationsText.replace(
    '{score}',
    `${state.score}/${numberOfQuestions}`
  );

  return (
    <div className={clsx(scss.gameHeader)}>
      <StyledHeading hasSwoosh={false}>{data.heading}</StyledHeading>
      <p className={clsx(scss.gameSubheader)}>
        {state.gameOver ? congratulationsText : data.subheading}
      </p>
    </div>
  );
};

export default Header;
