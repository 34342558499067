import * as React from 'react';
import clsx from 'clsx';

import { GameContext } from './GameContext';
import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import { Button } from 'src/components/ui/button/button';

import * as scss from './Game.module.scss';

// types
export type gameQuestionType = {
  category: string;
  question: string;
  choices: { text: string }[];
  answer: string;
};

export type gameDataType = {
  heading: string;
  categoryLabel: string;
  questionLabel: string;
  questionInstructions: string;
  continueButtonLabel: string;
  submitButtonLabel: string;
  thankYouLabel: string;
  yourScoreLabel: string;
  playAgainLabel: string;
  questions: gameQuestionType[];
};

export type Props = {
  data: gameDataType;
};

const Game: React.FC<Props> = ({ data }) => {
  // misc
  const isBrowser =
    typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined';
  const intervalDuration = 250;
  const duration = 4000;

  // refs
  const gameRef = React.useRef<HTMLDivElement | null>(null);
  const intervalRef = React.useRef<ReturnType<typeof setInterval>>();
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  // context
  const { state, dispatch } = React.useContext(GameContext);

  // states
  const [lockedInChoice, setLockedInChoice] = React.useState('');
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [randomPickResult, setRandomPickResult] = React.useState<number | null>(
    null
  );

  // Effect - On mount
  React.useEffect(() => {
    setIsDrawing(true);
  }, []);

  // Effect - Load game data
  React.useEffect(() => {
    dispatch({ type: 'LOAD_DATA', payload: data.questions });
  }, [data, dispatch]);

  // Effect - Drawing
  React.useEffect(() => {
    function randomPick(): number {
      const max = 5;
      const min = 0;
      const result = Math.floor(Math.random() * (max - min + 1)) + min;

      return state.selectedItems.includes(result) ? randomPick() : result;
    }

    if (isDrawing) {
      intervalRef.current = setInterval(() => {
        setRandomPickResult(randomPick());
      }, intervalDuration);

      timeoutRef.current = setTimeout(() => {
        if (!intervalRef.current) return;

        if (isDrawing) {
          clearInterval(intervalRef.current);

          setIsDrawing(false);
        }
      }, duration);
    } else {
      if (!intervalRef.current || !timeoutRef.current) return;

      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);

      setIsDrawing(false);
    }
  }, [isDrawing, state.selectedItems]);

  React.useEffect(() => {
    if (!isDrawing) {
      if (randomPickResult === null) return;

      dispatch({ type: 'CURRENT_ITEM', payload: randomPickResult });
    }
  }, [isDrawing, randomPickResult, dispatch]);

  // Effect - Lock in choice
  React.useEffect(() => {
    setLockedInChoice('');
  }, [state.currentItem]);

  // Effect - Analytics - Game Start
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      window.dataLayer.push({
        event: 'Random Trivia Game',
        action: 'gameStart',
      });
    }
  }, [isBrowser]);

  // Effect - Analytics - Game Over
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.gameOver) {
        window.dataLayer.push({
          event: 'Random Trivia Game',
          action: 'gameOver',
          data: {
            score: state.score,
          },
        });
      }
    }
  }, [isBrowser, state.gameOver, state.score]);

  // Effect - Analytics - Question
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.currentItem !== undefined) {
        window.dataLayer.push({
          event: 'Random Trivia Game',
          action: 'quizQuestion',
          data: {
            progress: `${state.progress}`,
            question: state.gameData[state.currentItem].question,
            category: state.gameData[state.currentItem].category,
          },
        });
      }
    }
  }, [isBrowser, state.gameData, state.currentItem, state.progress]);

  // Effect - Analytics - Choice
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.currentItem !== undefined) {
        if (state.currentChoice) {
          window.dataLayer.push({
            event: 'Random Trivia Game',
            action: 'quizChoice',
            data: {
              answer: state.currentChoice,
              correctAnswer: state.gameData[state.currentItem].answer,
            },
          });
        }
      }
    }
  }, [isBrowser, state.gameData, state.currentChoice, state.currentItem]);

  /**
   * Submit locked in answer
   */
  function submitLockedInAnswer() {
    dispatch({
      type: 'SUBMIT_ANSWER',
      payload: lockedInChoice,
    });
  }

  /**
   * Proceed to next Item
   */
  function proceed() {
    if (gameRef.current) {
      gameRef.current.scrollIntoView();
    }

    setLockedInChoice('');

    if (state.progress === state.maxGameLength) {
      dispatch({ type: 'CURRENT_ITEM' });
    } else {
      setIsDrawing(true);
    }

    // GA_TAG_EVENT
    if (isBrowser) {
      window.dataLayer.push({
        event: 'Random Trivia Game',
        action: 'quizContinue',
      });
    }
  }

  /**
   * Resets the game
   */
  function reset() {
    dispatch({ type: 'RESET_GAME' });

    setIsDrawing(true);

    if (gameRef.current) {
      gameRef.current.scrollIntoView();
    }

    // GA_TAG_EVENT
    window.dataLayer.push({
      event: 'Random Trivia Game',
      action: 'gameRestart',
    });
  }

  /**
   * Get the question status text, replacing current and total numbers
   */
  function getQuestionStatus() {
    return data.questionLabel
      .replace('{{CURRENT}}', '' + state.progress)
      .replace('{{TOTAL}}', '' + state.maxGameLength);
  }

  /**
   * Get the game score text, replacing score token
   */
  function getScore() {
    return data.yourScoreLabel.replace('{{SCORE}}', '' + state.score);
  }

  return state.gameData.length > 0 ? (
    <div ref={gameRef} className={clsx(scss.wrapper)} id={'activity-section'}>
      {/* Aria Live */}
      <div className="visually-hidden" aria-live={`polite`}>
        {!state.gameOver &&
          isDrawing &&
          lockedInChoice === '' &&
          `Please wait a moment as a random category is selected.`}

        {!state.gameOver &&
          !isDrawing &&
          lockedInChoice === '' &&
          `The random category for question ${state.progress} is ${
            state.currentItem !== undefined
              ? state.gameData[state.currentItem].category
              : ''
          }. The question is ${
            state.currentItem !== undefined
              ? state.gameData[state.currentItem].question
              : ''
          }. Please select one of the three answer buttons to choose your answer.`}

        {!state.gameOver &&
          !isDrawing &&
          !state.currentChoice &&
          lockedInChoice &&
          `You selected ${lockedInChoice}. Select the "${data.submitButtonLabel}" button below to lock in your choice, or select another answer.`}

        {!state.gameOver &&
          !isDrawing &&
          state.progress !== state.maxGameLength &&
          state.currentChoice &&
          state.currentItem !== undefined &&
          state.currentChoice !== state.gameData[state.currentItem].answer &&
          `Sorry, that's incorrect. The correct answer is ${
            state.gameData[state.currentItem].answer
          }. Please select the ${
            data.continueButtonLabel
          } button for the next question.`}

        {!state.gameOver &&
          !isDrawing &&
          state.progress === state.maxGameLength &&
          state.currentChoice &&
          state.currentItem !== undefined &&
          state.currentChoice !== state.gameData[state.currentItem].answer &&
          `Sorry, that's incorrect. The correct answer is ${
            state.gameData[state.currentItem].answer
          }. Please select the "FINISH GAME" button to finish.`}

        {!state.gameOver &&
          !isDrawing &&
          state.progress !== state.maxGameLength &&
          state.currentChoice &&
          state.currentItem !== undefined &&
          state.currentChoice === state.gameData[state.currentItem].answer &&
          `That's correct! Please select the "${data.continueButtonLabel}" button for the next question`}

        {!state.gameOver &&
          !isDrawing &&
          state.progress === state.maxGameLength &&
          state.currentChoice &&
          state.currentItem !== undefined &&
          state.currentChoice === state.gameData[state.currentItem].answer &&
          `That's correct! Please select the "FINISH GAME" button to finish.`}

        {state.gameOver &&
          `Thank you for playing! Your score is ${state.score}. Select the "${data.playAgainLabel}" button to get three more random questions.`}
      </div>

      <div className={clsx(scss.container)}>
        {/* heading */}
        <div className={clsx(scss.heading)}>
          <StyledHeading hasSwoosh={false}>{data.heading}</StyledHeading>
        </div>

        {/* Screen - Game Over */}
        {state.gameOver && (
          <div className={clsx(scss.screenGameOver)}>
            <div className={clsx(scss.content)}>
              <p>
                {data.thankYouLabel}
                <br />
                {getScore()}
              </p>
            </div>
            <div className={clsx(scss.cta)}>
              <Button
                className={clsx(scss.btn_action)}
                tag="button"
                label={data.playAgainLabel}
                onClick={reset}
              />
            </div>
          </div>
        )}

        {/* Screen - Game */}
        {!state.gameOver && (
          <div className={clsx(scss.screenGame)}>
            <div className={clsx(scss.screenGameGrid)}>
              {/* column 1 */}
              <div className={clsx(scss.screenGameGridItem)}>
                {/* category */}
                <div className={clsx(scss.category)}>
                  {data.categoryLabel}:{' '}
                  {!isDrawing && state.currentItem !== undefined
                    ? state.gameData[state.currentItem].category
                    : ''}
                </div>

                <div
                  className={clsx(scss.categoryWheel)}
                  aria-label={`Wheel of Categories. There are six categories. The randomly selected category is ${
                    state.currentItem !== undefined
                      ? state.gameData[state.currentItem].category
                      : ''
                  }.`}>
                  <div
                    className={clsx(scss.categoryWheelInner)}
                    aria-hidden={true}>
                    {state.gameData.map((item, index) => (
                      <svg
                        key={`category_wheel_item_${index}`}
                        className={clsx(
                          scss.categoryWheelItem,
                          state.selectedItems.includes(index) &&
                            scss.categoryWheelItemDone,
                          index === randomPickResult &&
                            scss.categoryWheelItemActive
                        )}
                        viewBox="0 0 100 87">
                        <polygon points="0 0, 100 0, 50 87" textLength="30%" />
                        <switch>
                          <foreignObject x="25%" y="0" width="50%" height="50%">
                            <p>{item.category}</p>
                          </foreignObject>
                        </switch>
                      </svg>
                    ))}
                  </div>
                </div>
              </div>

              {state.currentItem !== undefined && !isDrawing && (
                <>
                  {/* column 2 */}
                  <div className={clsx(scss.grid_item)}>
                    {/* progress */}
                    <div className={scss.progress}>{getQuestionStatus()}</div>

                    {/* question */}
                    <div className={scss.question}>
                      {state.gameData[state.currentItem].question}
                    </div>

                    <div className={scss.helper}>
                      {data.questionInstructions}
                    </div>

                    {/* choices */}
                    <ul
                      className={clsx(scss.choiceList)}
                      aria-label={`Three Answer Buttons`}>
                      {state.gameData[state.currentItem].choices.map(
                        (choice, choiceIndex) => (
                          <li
                            key={`game_choice_list_item_${choiceIndex}`}
                            className={clsx(
                              choice === lockedInChoice && 'is-selected',
                              state.currentChoice &&
                                state.currentItem !== undefined &&
                                state.currentChoice ===
                                  state.gameData[state.currentItem].choices[
                                    choiceIndex
                                  ] &&
                                state.currentChoice !==
                                  state.gameData[state.currentItem].answer &&
                                'is-error',
                              state.currentChoice &&
                                state.currentItem !== undefined &&
                                state.gameData[state.currentItem].answer ===
                                  state.gameData[state.currentItem].choices[
                                    choiceIndex
                                  ] &&
                                'is-success'
                            )}>
                            <button
                              aria-label={
                                state.currentChoice &&
                                state.currentItem !== undefined
                                  ? state.gameData[state.currentItem].answer ===
                                    state.gameData[state.currentItem].choices[
                                      choiceIndex
                                    ]
                                    ? `Correct Answer: ${choice}`
                                    : `Incorrect Answer: ${choice}`
                                  : `Answer ${choiceIndex + 1}: ${choice}`
                              }
                              onClick={() => {
                                if (!state.currentChoice) {
                                  setLockedInChoice(choice);
                                }
                              }}>
                              {choice}
                            </button>
                          </li>
                        )
                      )}
                    </ul>

                    {/* controls */}
                    <ul
                      className={clsx(scss.controlList)}
                      aria-label={`Quiz Action Buttons`}>
                      <li>
                        <Button
                          className={clsx(scss.btn_action)}
                          tag={`button`}
                          label={data.submitButtonLabel}
                          disabled={
                            !lockedInChoice || state.currentChoice !== null
                          }
                          onClick={submitLockedInAnswer}
                        />
                      </li>
                      <li>
                        <Button
                          className={clsx(scss.btn_action)}
                          tag={`button`}
                          label={
                            state.progress !== state.maxGameLength
                              ? data.continueButtonLabel
                              : 'FINISH GAME'
                          }
                          disabled={!state.currentChoice}
                          onClick={proceed}
                        />
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {/* <ul className={clsx(scss.console)}>
        <li>ITEM: {String(randomPickResult)}</li>
        <li>IS_DRAWING: {String(isDrawing)}</li>
        <li>LIST: {state.selectedItems.join(' | ')}</li>
        <li>GAME_OVER: {String(state.gameOver)}</li>
        <li>SCORE: {String(state.score)}</li>
      </ul> */}
    </div>
  ) : null;
};

export default Game;
