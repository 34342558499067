import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import { CoachsCornerItem, CoachsCornerItemType } from './CoachsCornerItem';
import { StyledHeading } from '../ui/styledHeading/styledHeading';
import * as scss from './CoachsCorner.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type Props = {
  data: {
    backgroundImage?: types.ImageFields;
    headline: types.TextFields;
    footerContent?: string;
    content: CoachsCornerItemType[];
  };
  id?: string;
};

export const CoachsCorner = ({ data, id }: Props) => {
  // hooks
  const view = useView();

  // render
  return (
    <section id={id} className={clsx(scss.section)}>
      {data.backgroundImage?.src[view] && (
        <div className={clsx(scss.sectionBg)}>
          <img
            src={getStrapiImageSource(
              data.backgroundImage.src[view] as types.ImageSource
            )}
            alt={data.backgroundImage.alt}
          />
        </div>
      )}
      <div className={clsx(scss.container)}>
        <div className={clsx(scss.header)}>
          <StyledHeading hasSwoosh={false}>{data.headline.text}</StyledHeading>

          {data.footerContent && (
            <div
              className={clsx(scss.subheadline)}
              dangerouslySetInnerHTML={{
                __html: data.footerContent,
              }}
            />
          )}
        </div>
        <div className={clsx(scss.row)}>
          {data.content.map((item, i) => (
            <div key={i} className={clsx(scss.col)}>
              <CoachsCornerItem data={item} isOdd={i % 2 === 0} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
